<template>
  <div class="relative" style="min-height: 80vh; margin: -2.2rem;" v-if="showlanding" >

    <div style="height: 50vh; overflow: hidden;" class="absolute top-0 z-0 w-full">
      <svg class="hidden lg:block" viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
        <path d="M-40.31,151.09 C217.18,113.09 282.18,113.09 517.18,147.09 L500.00,0.00 L0.00,0.00 Z"  style="stroke: none; fill: #47E7B81A;"></path>
      </svg>
    </div>
    <div style="height: 40vh; overflow: hidden;" class="lg:hidden absolute top-0 z-0 w-full">
      <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
        <path d="M-40.31,151.09 C209.06,135.08 267.18,130.08 519.68,152.09 L500.00,0.00 L0.00,0.00 Z"  style="stroke: none; fill: #47E7B81A;"></path>
      </svg>
    </div>
    <div class=" z-50 relative p-10">
      <div class=" mb-5">
        <h1 class=" pb-5 lg:text-6xl text-2xl" style="font-weight: 800; color: #0E4333;">Fresh Clinics Digital Drugbook</h1>
        <h3 class=" pb-5 text-sm lg:text-lg" style="font-weight: 500; color: #0E4333;">Say goodbye to your old paper drugbook and hello to Fresh Clinics Digital Drugbook!</h3>
        <div style="background-color: #E5E7EB; height: 1px; width: 100%;"></div>
      </div>
      <div>
        <ul>
          <li class="flex items-center mb-2">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="18" height="18" rx="9" fill="#DEF7EC"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6947 5.70495C13.8259 5.83622 13.8996 6.01424 13.8996 6.19985C13.8996 6.38547 13.8259 6.56348 13.6947 6.69475L8.0947 12.2948C7.96343 12.426 7.78541 12.4997 7.5998 12.4997C7.41418 12.4997 7.23617 12.426 7.1049 12.2948L4.3049 9.49475C4.17739 9.36273 4.10683 9.18591 4.10842 9.00237C4.11002 8.81883 4.18364 8.64326 4.31342 8.51348C4.44321 8.38369 4.61878 8.31007 4.80232 8.30848C4.98585 8.30688 5.16268 8.37744 5.2947 8.50495L7.5998 10.8101L12.7049 5.70495C12.8362 5.57372 13.0142 5.5 13.1998 5.5C13.3854 5.5 13.5634 5.57372 13.6947 5.70495Z" fill="#0E9F6E"/>
            </svg>
            <p class=" lg:text-base text-xs"  style="font-weight: 500; color: #111928; line-height: 150%;" >Save time by completing your Drugbook entries post-treatment directly in the Fresh App</p>
          </li>
          <li class="flex items-center mb-2">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="18" height="18" rx="9" fill="#DEF7EC"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6947 5.70495C13.8259 5.83622 13.8996 6.01424 13.8996 6.19985C13.8996 6.38547 13.8259 6.56348 13.6947 6.69475L8.0947 12.2948C7.96343 12.426 7.78541 12.4997 7.5998 12.4997C7.41418 12.4997 7.23617 12.426 7.1049 12.2948L4.3049 9.49475C4.17739 9.36273 4.10683 9.18591 4.10842 9.00237C4.11002 8.81883 4.18364 8.64326 4.31342 8.51348C4.44321 8.38369 4.61878 8.31007 4.80232 8.30848C4.98585 8.30688 5.16268 8.37744 5.2947 8.50495L7.5998 10.8101L12.7049 5.70495C12.8362 5.57372 13.0142 5.5 13.1998 5.5C13.3854 5.5 13.5634 5.57372 13.6947 5.70495Z" fill="#0E9F6E"/>
            </svg>
            <p  class=" lg:text-base text-xs"  style="font-weight: 500; color: #111928; line-height: 150%;" >Get real-time insights into your inventory levels across each of your clinics</p>
          </li>
          <li class="flex items-center">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="18" height="18" rx="9" fill="#DEF7EC"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6947 5.70495C13.8259 5.83622 13.8996 6.01424 13.8996 6.19985C13.8996 6.38547 13.8259 6.56348 13.6947 6.69475L8.0947 12.2948C7.96343 12.426 7.78541 12.4997 7.5998 12.4997C7.41418 12.4997 7.23617 12.426 7.1049 12.2948L4.3049 9.49475C4.17739 9.36273 4.10683 9.18591 4.10842 9.00237C4.11002 8.81883 4.18364 8.64326 4.31342 8.51348C4.44321 8.38369 4.61878 8.31007 4.80232 8.30848C4.98585 8.30688 5.16268 8.37744 5.2947 8.50495L7.5998 10.8101L12.7049 5.70495C12.8362 5.57372 13.0142 5.5 13.1998 5.5C13.3854 5.5 13.5634 5.57372 13.6947 5.70495Z" fill="#0E9F6E"/>
            </svg>
            <p  class=" lg:text-base text-xs"  style="font-weight: 500; color: #111928; line-height: 150%;">Never miss recording a Drugbook entry with dynamic notifications and patient filters</p>
          </li>
        </ul>
      </div>
      <div class="flex w-full items-center justify-center mt-10">
          <vs-button color="primary" class="mr-10" @click="redirectToSetup" >
            Get Started
          </vs-button>
          <vs-button color="primary" type="border" @click="redirectToLearnMore">
              Learn More
          </vs-button>
      </div>
    </div>
    <div class="flex items-center justify-center">
      <img class="hidden lg:block" src="../../../assets/images/white-iphone-in-portrait-position-macbook-pro-responsive-png-mockup-a12407.png" style="width: 807px; height: 506px;"/>
      <img class="block lg:hidden" src="../../../assets/images/white-iphone-in-portrait-position-macbook-pro-responsive-png-mockup-a12407.png" style="width: 375px; height: 235.13px;"/>
    </div>


  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { Validator } from "vee-validate";
import { mapActions, mapMutations } from "vuex";
import moment from "moment";
import StaffHelper from "@/mixins/Staff"

const dict = {
  custom: {
    dateAdded: {
      required: "Please enter the date added",
    },
  },
};

Validator.localize("en", dict);
Vue.prototype.moment = moment;
export default {
  mixins: [StaffHelper],
  inject: ["getRoleRouteName"],
  data: () => ({
    organizationId: "",
    token: undefined,
    embedClinicId: undefined,
    userId: "",
  }),
  methods: {
    ...mapActions("drugbook", [
      "fetchDrugEntries",
      "getFirstEntryDate",
      "getAmountPerClinic",
      "getAssociatedClinics",
    ]),
    ...mapActions("organization", ["fetchOrganizationAllClinics"]),
    ...mapActions("product", ["fetchProducts"]),
    ...mapActions("clinic", ["fetchClinicsDetail"]),
    ...mapMutations("admin",["SET_BEARER"]),
    redirectToSetup() {
      if ( localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}EmbededData`)){
        this.$router.push({ path: "/drugbook/setup" });
      }else{
        this.$router.push({ name: this.getRoleRouteName("Drugbook") });
      }
    },
    redirectToLearnMore() {
      window.open('https://answers.freshclinics.com.au/knowledge/drugbook-goes-digital')
    },
    async getClinics() {
      if(this.$route.path.includes("org-owner")){
        const hasDrugEntry = await this.checkClinicDrugEntry();
        const organizationStatus = await this.getOrganizationComplianceStatus();
        const isDrugbookEnabled = organizationStatus.isDrugbookEnabled;
        if (
          !(isDrugbookEnabled && hasDrugEntry) &&
          !(isDrugbookEnabled && !hasDrugEntry) &&
          !(!isDrugbookEnabled && hasDrugEntry)
        ) {
          this.$router.push("/org-owner/notice-board");
          return;
        }
      }
      await this.fetchclinics();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.fullPath;
    });
  },
  async created() {
    this.getClinics();
  },
};
</script>

<style lang="scss">
.custom-shape-divider-top-1676341318 {
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1676341318 svg {
  top: 25%;
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 225px;
}

.custom-shape-divider-top-1676341318 .shape-fill {
    fill: #47E7B81A;
    height: 50%;
}
.bg-custom{
  background-color: #47E7B81A ;
}
</style>
